html,
body {
    width: 100%;
    min-height: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    background-color: #EEE;
    min-height: 100%;
    .sidebar {
    }
}

#main {
    display: flex;
    flex: auto;
    margin-top: 90px;
}
.app_bar_avatar {
    text-decoration: none;
    display: flex;
    align-items: center;
    .MuiAvatar-root {
        margin-right: 5px;
    }
}
.content.feed {
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    &.large {
        max-width: 650px;
    }
    @media (max-width: 600px) {
        max-width: 100%;
    }
}