body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  background-color: #EEE;
  min-height: 100%;
}
#main {
  display: flex;
  flex: auto;
  margin-top: 90px;
}

.app_bar_avatar {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.app_bar_avatar .MuiAvatar-root {
  margin-right: 5px;
}

.content.feed {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}
.content.feed.large {
  max-width: 650px;
}
@media (max-width: 600px) {
  .content.feed {
    max-width: 100%;
  }
}
